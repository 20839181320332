exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-funnel-1-index-tsx": () => import("./../../../src/pages/funnel-1/index.tsx" /* webpackChunkName: "component---src-pages-funnel-1-index-tsx" */),
  "component---src-pages-funnel-1-quiz-tsx": () => import("./../../../src/pages/funnel-1/quiz.tsx" /* webpackChunkName: "component---src-pages-funnel-1-quiz-tsx" */),
  "component---src-pages-funnel-1-summary-tsx": () => import("./../../../src/pages/funnel-1/summary.tsx" /* webpackChunkName: "component---src-pages-funnel-1-summary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

