import { avatars } from "./avatars";
import { Avatar } from "./types";

export const DEFAULT_AVATAR: Avatar = {
  id: avatars[0].id,
  emotionalAttributes: [],
  hobbies: [],
  qualityAttributes: [],
  personalityAttributes: [],
};
