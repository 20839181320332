import { AvatarDescriptor } from "./types";

export const avatars: AvatarDescriptor[] = [
  {
    id: "sarah",
    name: "Sarah Thompson",
    age: 37,
    description: "I am a confident redhead seeking emotional fulfillment and deep connection.",
  },
  {
    id: "lindsay",
    name: "Lindsay Summers",
    age: 24,
    description:
      "I am a lively social media influencer who enjoys both public challenges and meaningful connections in private.",
  },
  {
    id: "oksana",
    name: "Oksana Gordenko",
    age: 45,
    description: "I am a skilled corporate attorney known for her professional prowess.",
  },
  {
    id: "katarzyna",
    name: "Katarzyna Nowak",
    age: 27,
    description:
      "This adventurous brunette embraces life's adventures with enthusiasm, in both her personal and intimate experiences.",
  },
  {
    id: "ella",
    name: "Ella Morgan",
    age: 33,
    description:
      "I'm Ella, a loving soul who dreams of having a big, happy family filled with love and laughter.",
  },
  {
    id: "francesca",
    name: "Francesca",
    age: 29,
    description:
      "I am a reserved Spanish beauty, deeply passionate about art and literature, with a heart full of love.",
  },
  {
    id: "nattaya",
    name: `Nattaya "Nat" Srisuwan`,
    age: 20,
    description:
      "I am a serious Thai student with a gentle nature, offering understanding and support.",
  },
  {
    id: "natalia",
    name: "Natalia Petrova",
    age: 21,
    description:
      "I am a skilled ballet dancer who cherishes romantic moments and enjoys sharing personal connections.",
  },
  {
    id: "jade",
    name: "Jade",
    age: 27,
    description:
      "I'm Jade, a passionate gamer who loves the thrill of competition—especially when I'm playing alongside someone special. Let's level up together!",
  },
  {
    id: "isla",
    name: "Isla Monroe",
    age: 36,
    description:
      "I'm Isla, a farm girl with dreams of owning my own land and creating a peaceful life close to nature.",
  },
  {
    id: "madison",
    name: "Madison Hughes",
    age: 31,
    description:
      "I'm Madison, a stewardess with a passion for travel and exploring new places. Adventure is always on my itinerary.",
  },
  {
    id: "olivia",
    name: "Austin Walker",
    age: 31,
    description:
      "I'm Austin Walker, a rodeo-loving, BBQ enthusiast who enjoys the simple pleasures of country life. Let's savor good times together!",
  },
  {
    id: "zara",
    name: "Zara Blake",
    age: 25,
    description: "I'm a city girl who loves fashion, parties, and making new friends.",
  },
  {
    id: "sophia",
    name: "Sophia Reed",
    age: 21,
    description:
      "I'm Sophia, a sensitive soul who loves the quiet comfort of staying indoors and connecting deeply with those I care about.",
  },
  {
    id: "aiko",
    name: "Aiko Tanaka",
    age: 38,
    description:
      "I'm Aiko Tanaka, a university professor who loves teaching, dancing to rock music on weekends.",
  },
  {
    id: "aimo",
    name: "Aimo",
    age: 22,
    description: "I am Aimo, a romantic at heart longing for fulfillment and companionship.",
  },
  {
    id: "penelope",
    name: "Penelope",
    age: 27,
    description:
      "I am a vibrant traveler seeking cultural experiences and connections with others.",
  },
];
