import { Helmet } from "react-helmet";
import React from "react";
import { useSiteMetadata } from "@hooks/useSiteMetadata";

export type SEOProps = {
  title?: string;
  description?: string;
  pathname?: string;
  image?: string;
  children?: React.ReactNode;
};

export function GlobalHead() {
  const { title, description, siteUrl, image } = useSiteMetadata();

  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <link rel="canonical" href={siteUrl} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={siteUrl} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <meta name="facebook-domain-verification" content="f77cds66xztr7hc40tw7fkrxza3a9y" />
    </Helmet>
  );
}
