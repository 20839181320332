export type Product = {
  // All frontend data goes here
  unit_amount_before: number;
  unit_amount_daily: number;
  unit_amount_daily_before: number;
  // All shopify backend data goes here
  shopify: {
    price: {
      id: string;
      currency: string;
      unit_amount: number;
      recurring: {
        interval: string;
        interval_count: number;
      };
    };
    product: {
      id: string;
      name: string;
      metadata: {
        beforePrice: string;
        label?: string;
        product_category: string;
      };
    };
  };
};

export const products: Product[] = [
  {
    unit_amount_before: 19999,
    unit_amount_daily: 28,
    unit_amount_daily_before: 111,
    shopify: {
      price: {
        id: "price_1QFGhQBH9hnfjwPHglbXHBLz",
        currency: "usd",
        unit_amount: 4999,
        recurring: {
          interval: "month",
          interval_count: 6,
        },
      },
      product: {
        id: "prod_R7VivyrPrdbZEP",
        name: "6 month plan",
        metadata: {
          beforePrice: "19999",
          label: "Best value",
          product_category: "regular_monthly_subscription",
        },
      },
    },
  },
  {
    unit_amount_before: 11199,
    unit_amount_daily: 41,
    unit_amount_daily_before: 124,
    shopify: {
      price: {
        id: "price_1QFGgTBH9hnfjwPH4ZJaKAJR",
        currency: "usd",
        unit_amount: 3699,
        recurring: {
          interval: "month",
          interval_count: 3,
        },
      },
      product: {
        id: "prod_R7Vhez3D8xQEdO",
        name: "3 month plan",
        metadata: {
          beforePrice: "11199",
          label: "Most popular",
          product_category: "regular_monthly_subscription",
        },
      },
    },
  },
  {
    unit_amount_before: 5999,
    unit_amount_daily: 99,
    unit_amount_daily_before: 199,
    shopify: {
      price: {
        id: "price_1QFGfPBH9hnfjwPHEjghPlls",
        currency: "usd",
        unit_amount: 2999,
        recurring: {
          interval: "month",
          interval_count: 1,
        },
      },
      product: {
        id: "prod_R7VgK1yE5yKfNn",
        name: "1 month plan",
        metadata: {
          beforePrice: "5999",
          product_category: "regular_monthly_subscription",
        },
      },
    },
  },
];
