import * as React from "react";
import { merge } from "lodash";
import "../../styles/global.css";
import { GlobalHead } from "./head";
import { LocationProvider, useLocation } from "@gatsbyjs/reach-router";
import * as Sentry from "@sentry/gatsby";
import posthog from "posthog-js";
import { Product, products } from "src/products";
import { navigate } from "gatsby";
import { useSiteMetadata } from "@hooks/useSiteMetadata";
import { Avatar, User } from "@data/types";
import { DEFAULT_USER } from "@data/defaultUser";
import { DEFAULT_AVATAR } from "@data/defaultAvatar";

type RootState = {
  products: Product[];
  selectedProductID: string;
  setSelectedProductID: (id: string) => void;
  offerTime: number;
  setOfferTime: (time: number) => void;
  user: User;
  updateUser: (user: Partial<User>) => void;
  avatar: Avatar;
  updateAvatar: (avatar: Partial<Avatar>) => void;
};

const RootStateContext = React.createContext<RootState | null>(null);

export function useRootState(): RootState {
  const ctx = React.useContext(RootStateContext);
  if (!ctx) {
    throw new Error("useRootState must be used within RootStateContext");
  }

  return ctx;
}

/** Wraps every page but is not re-mounted when chaning pages */
export function RootWrapper(props: React.PropsWithChildren) {
  const [user, setUser] = React.useState<User>(DEFAULT_USER);
  const [avatar, setAvatar] = React.useState<Avatar>(DEFAULT_AVATAR);
  const [offerTime, setOfferTime] = React.useState<number>(899000);
  const [selectedProductID, setSelectedProductID] = React.useState<string>(
    products[0].shopify.product.id
  );

  React.useEffect(() => {
    // Additional data passed to Sentry that ensures easier issue debugging
    const posthogID = posthog.get_distinct_id();
    if (posthogID) {
      Sentry.setUser({
        posthogID: posthogID,
      });
    }
  }, []);

  const value = React.useMemo<RootState>(() => {
    return {
      products,
      offerTime,
      setOfferTime,
      selectedProductID,
      setSelectedProductID,
      user,
      updateUser: (user) => {
        setUser((it) => {
          return { ...merge(it, user) };
        });
      },
      avatar,
      updateAvatar: (avatar) => {
        setAvatar((it) => {
          return { ...merge(it, avatar) };
        });
      },
    };
  }, [offerTime, user, selectedProductID]);

  return (
    <RootStateContext.Provider value={value}>
      <LocationProvider>
        <GlobalHead />
        {/* <RedirectOnMissingProfile /> */}

        {props.children}
      </LocationProvider>
    </RootStateContext.Provider>
  );
}

function RedirectOnMissingProfile() {
  const location = useLocation();
  const { user: userProfile } = useRootState();
  const meta = useSiteMetadata();

  React.useEffect(() => {
    // Regular expression to match "/funnel-1/" followed by at least one character
    const isInFunnelUrl = /\/funnel-1\/.+/.test(location.pathname);
    if (isInFunnelUrl && !userProfile) {
      console.warn(`[${meta.brandName}] redirecting: no user profile was found`);
      navigate("/funnel-1");
    }
  }, []);

  return null;
}
